import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout"
import Seo from "@components/seo"
import GettingXywavComponent from "@components/gettingXywav"

const GettingXywavNarcPage = ({ location, data }) => {
  const videoGallery = [
    {
      wistiaId: "ztv15t0i9p",
      title: (
        <>
          Introduction to the JazzCares<sup>&reg;</sup> Nurse Program
        </>
      ),
      description: (
        <>
          Learn what to expect from your JazzCares<sup>&reg;</sup> Nurse Case
          Manager, who is assigned to work with you from the very beginning to
          help you through every step of starting XYWAV and beyond
        </>
      ),
      thumbnail: data.vidThumb1,
      thumbnailSmall: data.vidThumb1Small,
      alt: "Watch video: Introduction to the JazzCares® Nurse Program",
      id: 0,
      metadata: {
        name: "Introduction to the JazzCares® Nurse Program | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn what to expect from your JazzCares® Nurse Case Manager, who is assigned to work with you from the very beginning to help you through every step of starting XYWAV and beyond. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb1.publicURL,
        uploadDate: "2023-11-13",
        contentUrl: `https://www.xywav.com/getting-xywav/?wvideo=ztv15t0i9p`,
        duration: "PT4M16S",
      },
    },
    {
      wistiaId: "4b3gfw312k",
      title: "Enrolling in the Risk Evaluation and Mitigation Strategy (REMS)",
      description:
        "Understand what the XYWAV and XYREM REMS is, the enrollment process, and how to schedule your first prescription through the Certified Pharmacy",
      thumbnail: data.vidThumb2,
      thumbnailSmall: data.vidThumb2Small,
      alt: "Watch video: Enrolling in the Risk Evaluation and Mitigation Strategy (REMS)",
      id: 1,
      metadata: {
        name: "Enrolling in the Risk Evaluation and Mitigation Strategy (REMS) | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Understand what the XYWAV and XYREM REMS is, the enrollment process, and how to schedule your first prescription through the Certified Pharmacy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb2.publicURL,
        uploadDate: "2023-11-13",
        contentUrl: `https://www.xywav.com/getting-xywav/?wvideo=4b3gfw312k`,
        duration: "PT6M1S",
      },
    },
    {
      wistiaId: "kwlskiyeg4",
      title: (
        <>
          Getting your
          <br />
          prescription
        </>
      ),
      description:
        "Learn about the different resources that will be available as you get started on XYWAV",
      thumbnail: data.vidThumb3,
      thumbnailSmall: data.vidThumb3Small,
      alt: "Watch video: Getting your prescription",
      id: 2,
      metadata: {
        name: "Getting your prescription | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about the different resources that will be available as you get started on XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb3.publicURL,
        uploadDate: "2023-11-13",
        contentUrl: `https://www.xywav.com/getting-xywav/?wvideo=kwlskiyeg4`,
        duration: "PT4M26S",
      },
    },
  ]

  return (
    <Layout
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      exitModalColour="orange"
      copyright={
        <>
          &copy; 2023 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <nobr>US-XYW-2200416 Rev1123</nobr>
        </>
      }
    >
      <Seo
        title="How to Get a XYWAV® Prescription | XYWAV®"
        description="Learn about how you can get access to XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
        canonicalLocation="/getting-xywav/"
        videoMetadata={videoGallery}
      />

      <GettingXywavComponent
        theme="narcolepsy"
        location={location}
        videoGallery={videoGallery}
      />
    </Layout>
  )
}
export default GettingXywavNarcPage

export const query = graphql`
  query {
    vidThumb1: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-1.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb1Small: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb2: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-2.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb2Small: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb3: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-3.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb3Small: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
  }
`
